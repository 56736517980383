*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body { padding-top: 56px; }
@media screen and (max-width: 768px) {
    body { padding-top: 56px; }
}


