@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2:wght@500&display=swap');
.aboutPage{
    background-color: black;
    /* height: 300vh; */
}
.firstBanner{
    background: url('../../images/together.jpg');
    max-width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 130vh;
    border-radius: 0% 0% 50% 50%/0% 0% 100% 100%;
}

.firstBanner > div{
    /* border: 1px solid red; */
    position: relative;
    top: 40%;
    left: 55%;
    width: 45%;
    padding: 2vmax 3vmax;
    color: white;
}

.firstBanner > div > h1{
    font-size: 3.5rem;
    font-family: "Roboto";
}



.firstBanner > div > p{
    margin-top: 20px;
    font-family: 'Baloo Bhaijaan 2', cursive;
    font-size: 1rem;
}


.mission{
    display: flex;
    /* background-color: violet; */
    width: 90%;
    margin: auto;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 4rem;
    color: white;
    margin-bottom: 4rem;
}

.mission > div:nth-child(1){
    font-size: 2.5rem;
    font-weight: bold;
    font-family: "Roboto";
    padding: 0 1rem ;
}

.mission > div:nth-child(2){
    padding-top: 9rem;
}

.mission > div:nth-child(2)> h4{
    font-family: "Roboto";
}

.mission > div:nth-child(2)> p{
    font-family: 'Baloo Bhaijaan 2', cursive;
    font-weight: 500;
}

.save{
    background-color: turquoise;
    background: #EB3349;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #F45C43, #EB3349);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #F45C43, #EB3349); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
    color: white;
}
.save > div{
    width: 60%;
    margin: auto;
    text-align: center;
}
.save >div>h1{
   
    padding: 1rem 9rem;
    font-family: 'Montserrat';
}
.save > div > h6{
   font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
   padding: 1.5rem 0;
   font-weight: bolder;
}
.save > div > b{
    letter-spacing: 1px;
}

.save > div> div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.save > div> div>  img {
    width: 15%;
    cursor: pointer;
    margin: 2vmax 1vmax;
}
