.contact-container{
    position: relative;
    margin: 10% 25%;
    background-color: white; 
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.5);

}

.contact-container-item{
    width: 100%;
    align-self: center;
}

.contact-form{
    display: flex;
    flex-wrap: wrap;
}


.contact-form-item{
    position: relative;
    flex: 1 1 100%;
    margin: 15px 0px;
}



input, textarea:focus ~label ,
input, textarea:not(:placeholder-shown)~.label{
top: -10px;
left : 5px;
font-size: 14px;
background-color: #fff;
color: #1475f4;
font-weight: 600;
 
}




label {
    top: 10px;
    left: 10px;
    position: absolute;
    transition: all .5s;
}



.input{
    -webkit-transition: 0.3s; 
    width: 100%;
    padding: 10px ;
    border-radius: 5px;
    border: 2px solid lightgrey;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    outline: none;
    color: black;
    font-weight: normal;
}

.contact-form-item .input:focus {
    border: 2px solid #1d74e6;
}





.focus{
    top: -10px;
    left : 5px; 
    font-size: 12px;
    background-color: #fff;
    color: #1475f4;
    font-weight: 600;
}

.not-focus{
    color: grey;
    top:5px;
    left : 10px;
}






.btn{
    flex: 1 1 50%;
    background-color:rgb(74, 173, 212);
    color: white;
    margin: 10px 15px;
    padding: 10px;
    font-size: 16px;
}

.btn:hover{
    transition: all 0.3s;
    box-shadow: 0px 0px 5px 0.5px #5879da;
    background-color: #1d74e6;
    color: white;
}

.btn-container {
    display: flex;
}

.bg{
    position: absolute;
    background:linear-gradient(to right, #1d74e6, grey);
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
}