.lifeSave{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    /* align-items: center; */
    /* flex-basis: 33%; */
    width: 90%;
    /* background-color: aqua; */
    margin: auto;
    
}

.slogan{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.slogan h5{
    padding-top: 5px;
}

.bookmark-cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.bookmark-cards .MuiPaper-root{
    margin: 15px 0px;
}

@media screen and (max-width: 600px) {
    .lifeSave{
        width: 95%;
    }
    .slogan h2{
        font-size: 18px;
    }
    .slogan h5{
        font-size: 15px;
    }
}
  